import team1 from "../../../assets/Images/Team/team1.png";

export const OurTeamImg = [
  {
    id: 1,
    image: team1,
    title: "Leakey Nyamweya",
    subtitle: "CEO",
  },
  {
    id: 2,
    image: "",
    title: "Malach Salama",
    subtitle: "Senior Dev",
  },
  {
    id: 3,
    image: "",
    title: "Brian Areri",
    subtitle: "Junior Developer",
  },
  {
    id: 4,
    image: "",
    title: "KLEIN MATANCHI",
    subtitle: "ACOUNTS EXPERT",
  },
  // {
  //   id: 5,
  //   image: "",
  //   title: "Monika Kapoor",
  //   subtitle: "Assistant Manager",
  // },
  // {
  //   id: 6,
  //   image: "",
  //   title: "Robin Roy",
  //   subtitle: "CEO",
  // },
  // {
  //   id: 7,
  //   image: "",
  //   title: "Rubina Kapoor",
  //   subtitle: "Project Manager",
  // },
  // {
  //   id: 8,
  //   image: "",
  //   title: "Marteen Ray",
  //   subtitle: "Manager",
  // },
];
export default OurTeamImg;
