export const OurPricingData = [
  {
    id: 1,
    title: "Off the top",
    newPrice: "Reach out",
    // oldPrice: "$120",
    subTitle: "Per Month - Year",
    contain1: "5 Pages (Dynamic Website)",
    contain2: "10 Email IDs ",
    contain3: "Google Analytics Integration",
    contain4: "Social Media Sharing Ontion",
    contain5: "Hosing (1 year Free)",
    contain6: "Domain (1 Year Free)",
  },
  {
    id: 2,
    title: "Standard",
    newPrice: "Reach out",
    // oldPrice: "$140",
    subTitle: "Per Month - Year",
    contain1: "8-10 Pages (Dynamic Website)",
    contain2: "50+ Email IDs ",
    contain3: "Google Analytics Integration",
    contain4: "Social Media Sharing Ontion",
    contain5: "Hosting (1 year Free)",
    contain6: "Domain (1 Year Free)",
  },
  {
    id: 3,
    title: "Enhanced",
    newPrice: "Reach out",
    // oldPrice: "$250",
    subTitle: "Per Month - Year",
    contain1: "Shopping Cart, Payment Gateway",
    contain2: "Unlimited emails ",
    contain3: "Google Analytics Integration",
    contain4: "Social Media Sharing Ontion",
    contain5: "Hosing (1 year Free)",
    contain6: "Domain (1 Year Free)",
  },
];
export default OurPricingData;
