import React from "react";
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ThemeLogo from "../../assets/Images/BrandLogo/ThemeLogo.png";
import BlackThemeLogo from "../../assets/Images/BrandLogo/ThemeLogo.png";
import { useState } from "react";
const Header = (props) => {
  var className = "inverted";
  const [addClass, setAddClass] = useState(" ");
  const [showDropdown, setShowDropdown] = useState(false);
  var scrollTrigger = 20;

  const onToggle = () => {
    document.getElementById("myOverlay").style.display = "block";
    document.getElementById("stamenu").className = "active";
  };

  const onCloseNav = () => {
    document.getElementById("myOverlay").style.display = "none";
    document.getElementById("stamenu").className = "";
  };

  window.onscroll = function () {
    setAddClass();
    if (
      window.scrollY >= scrollTrigger ||
      window.pageYOffset >= scrollTrigger
    ) {
      document.getElementsByTagName("nav")[0].classList.add(className);
      // setLogo("blackLogo");
    } else {
      document.getElementsByTagName("nav")[0].classList.remove(className);
      // setLogo("whiteLogo");
    }
  };

  // const [click, setClick] = React.useState(false);
  // const handleClick = () => setClick(!click);
  return (
    <>
      <div className={addClass}>
        <div className="header">
          <div className="  sticky-top " id="stamenu">
            <Navbar expand="lg" collapseOnSelect>
              <div className="container">
                <div className="inner-header">
                  <Navbar.Brand href="/home">
                    <img
                      src={ThemeLogo}
                      alt="themeLogo"
                      className="img-fluid mx-auto WLogo"
                    />
                    <img
                      src={BlackThemeLogo}
                      alt="themeLogo"
                      className="img-fluid mx-auto BLogo"
                    />
                  </Navbar.Brand>
                  <Button
                    className="btn-navbar navbar-toggle"
                    onClick={onToggle}
                    data-toggle="collapse"
                    data-target=".nabar-ex1-collapse"
                  >
                    <i className="fa fa-bars" aria-hidden="true"></i>
                  </Button>

                  <div id="mySidenav" className="sidenav menu-vertical">
                    <div className="close-nav hidden-md hidden-lg hidden-xl ">
                      <span>Menu</span>
                      <Button
                        className="closebtn pull-right"
                        onClick={onCloseNav}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </Button>
                    </div>

                    <Nav
                      className="justify-content-center"
                      id="myOverlay"
                      style={{ flex: 1 }}
                    >
                      <Nav.Link href="home">
                        <Link to="/home"> Home </Link>
                      </Nav.Link>
                      <NavDropdown
                        title="Element"
                        id="collasible-nav-dropdown"
                        show={showDropdown}
                        onMouseLeave={() => setShowDropdown(false)}
                        onMouseOver={() => setShowDropdown(true)}
                      >
                        <NavDropdown.Item href="#faq">
                          <Link to="/faq">FAQ</Link>
                        </NavDropdown.Item>

                        <NavDropdown.Item href="#planing">
                          <Link to="/planing">Planing</Link>
                        </NavDropdown.Item>

                        <NavDropdown.Item href="#service">
                          <Link to="/service">Services</Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#contact">
                          <Link to="/contact">Contact</Link>
                        </NavDropdown.Item>
                        {/* <NavDropdown.Item href="#portfolio">
                          <Link to="/portfolio">Portfolio</Link>
                        </NavDropdown.Item> */}
                      </NavDropdown>

                      <Nav.Link href="#blog">
                        <Link to="/blog">Blog</Link>
                      </Nav.Link>
                      <Nav.Link href="#aboutus">
                        <Link to="/aboutus">About Us</Link>
                      </Nav.Link>
                      <Nav.Link href="#signin">
                        <span
                          onClick={() => props.onShowMenu(true)}
                          style={{ color: "black !important" }}
                        >
                          {/* <Link to="/signin">Sign in</Link> */}
                        </span>
                      </Nav.Link>
                    </Nav>
                  </div>
                  <div className="w3-overlay w3-animate-opacity"></div>
                  <button className="button">
                    <Link to="/contact"> Get A Free Quote </Link>
                  </button>
                </div>
              </div>
            </Navbar>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
