import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Icon1 from "../../../assets/Images/WeProvide/Icon1.png";
import Icon2 from "../../../assets/Images/WeProvide/Icon2.png";
import Icon3 from "../../../assets/Images/WeProvide/Icon3.png";
import Icon4 from "../../../assets/Images/WeProvide/Icon4.png";
import Icon5 from "../../../assets/Images/WeProvide/Icon5.png";
import Icon6 from "../../../assets/Images/WeProvide/Icon6.png";
import Aos from "aos";
import "aos/dist/aos.css";
export default function WeProvide() {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);
  return (
    <>
      <div className="container">
        <div className="weProvide spacing">
          <Row className="weProvideRow">
            <Col xl={10} lg={9} md={9} sm={9} xs={12}>
              <div className="titleFont">
                <h3>What we Provide</h3>
                <h2>We offer the best Popular of IT Services</h2>
              </div>
            </Col>
            <Col
              xl={2}
              lg={3}
              md={3}
              sm={3}
              xs={12}
              className="weProvideBtnCol"
            >
              <button className="button">
                <Link to="/service">All Services </Link>
              </button>
            </Col>
          </Row>
          <Row data-aos="zoom-in">
            <Col xl={4} lg={4} md={4} sm={6}>
              <div className="weProBox">
                <div className="weProBoxImg">
                  <img
                    className="img-fluid mx-auto"
                    alt="AboutUs"
                    src={Icon1}
                  />
                </div>
                <div className="weProBoxText">
                  <h6>Cyber Security</h6>
                  <p className="paragraph">
                    Leakey Tech recognizes the critical role of cybersecurity in
                    today's digital age. By leveraging their expertise, they're
                    developing advanced security solutions to safeguard
                    businesses and individuals from the ever-evolving threats in
                    the digital landscape.
                  </p>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={4} sm={6}>
              <div className="weProBox">
                <div className="weProBoxImg">
                  <img
                    className="img-fluid mx-auto"
                    alt="AboutUs"
                    src={Icon2}
                  />
                </div>
                <div className="weProBoxText">
                  <h6>Web Interactive</h6>
                  <p className="paragraph">
                    Leakey Tech isn't just building hardware and software;
                    they're making it interactive! Their focus on web
                    development brings your ideas to life, creating engaging and
                    dynamic online experiences.
                  </p>
                </div>
              </div>
            </Col>

            <Col xl={4} lg={4} md={4} sm={6}>
              <div className="weProBox">
                <div className="weProBoxImg">
                  <img
                    className="img-fluid mx-auto"
                    alt="AboutUs"
                    src={Icon3}
                  />
                </div>
                <div className="weProBoxText">
                  <h6>Graphic Design</h6>
                  <p className="paragraph">
                    Leakey Tech isn't just about the internal workings; they
                    understand the power of aesthetics too. Their graphic design
                    team crafts visually compelling experiences, ensuring your
                    software and hardware not only function flawlessly but also
                    leave a striking impression.
                  </p>
                </div>
              </div>
            </Col>

            <Col xl={4} lg={4} md={4} sm={6}>
              <div className="weProBox">
                <div className="weProBoxImg">
                  <img
                    className="img-fluid mx-auto"
                    alt="AboutUs"
                    src={Icon4}
                  />
                </div>
                <div className="weProBoxText">
                  <h6>Corporate Solution</h6>
                  <p className="paragraph">
                    Leakey Tech goes beyond consumer products. Their corporate
                    solutions leverage their software, hardware, and security
                    expertise to tailor powerful tools that streamline
                    workflows, enhance data management, and elevate your
                    business operations to the next level.
                  </p>
                </div>
              </div>
            </Col>

            <Col xl={4} lg={4} md={4} sm={6}>
              <div className="weProBox">
                <div className="weProBoxImg">
                  <img
                    className="img-fluid mx-auto"
                    alt="AboutUs"
                    src={Icon5}
                  />
                </div>
                <div className="weProBoxText">
                  <h6>Web Development</h6>
                  <p className="paragraph">
                    Leakey Tech bridges the physical and digital divide with
                    their web development prowess. They craft user-friendly and
                    visually stunning websites and applications, ensuring your
                    online presence is not only informative but also engaging
                    and interactive.
                  </p>
                </div>
              </div>
            </Col>

            <Col xl={4} lg={4} md={4} sm={6}>
              <div className="weProBox">
                <div className="weProBoxImg">
                  <img
                    className="img-fluid mx-auto"
                    alt="AboutUs"
                    src={Icon6}
                  />
                </div>
                <div className="weProBoxText">
                  <h6>Apps Development</h6>
                  <p className="paragraph">
                    Leakey Tech isn't just building software, they're building
                    experiences. Their app development team creates intuitive
                    and feature-rich mobile applications that seamlessly
                    integrate with your hardware and web solutions, putting
                    powerful functionality at your fingertips.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
