import blog1 from "../../../assets/Images/Blog/blog1.png";
import blog2 from "../../../assets/Images/Blog/blog2.png";
import blog3 from "../../../assets/Images/Blog/blog3.png";
import blog4 from "../../../assets/Images/Blog/blog4.png";
import blog5 from "../../../assets/Images/Blog/blog5.png";
export const OurBlogData = [
  {
    id: 1,
    image: blog1,
    date: "14 March 2022",
    profile: "By Admin",
    title: "Internet of things.",
    contain:
      "Elevate your business operations with Leakey Tech Inc.'s cutting-edge Internet of Things (IoT) solutions. We bridge the gap between software and hardware, crafting custom IoT systems that streamline workflows, optimize data collection, and unlock valuable insights.  Our expertise in cybersecurity ensures your connected devices and data remain protected.  Partner with Leakey Tech Inc. to unlock the full potential of the IoT and transform your business into a data-driven powerhouse.",
  },
  {
    id: 2,
    image: blog2,
    date: "14 March 2022",
    profile: "By Admin",
    title: "Software Development.",
    contain:
      "Don't settle for ordinary software solutions. Partner with Leakey Tech Inc. and experience the future of software development. We combine cutting-edge innovation with a commitment to robust security. We'll not only craft a solution that meets your specific needs, but also prioritize user experience and protect your data from evolving cyber threats.",
  },
  {
    id: 3,
    image: blog3,
    date: "14 March 2022",
    profile: "By Admin",
    title: "Cyber Security.",
    contain:
      "In today's digital landscape, cybersecurity is paramount. Leakey Tech Inc. goes beyond traditional software development, offering comprehensive cybersecurity solutions. Our team leverages their expertise to build robust defenses for your systems, safeguarding sensitive data and mitigating cyber threats. Partner with Leakey Tech Inc. for peace of mind – we'll fortify your digital infrastructure and ensure your business operates with confidence.",
  },
  {
    id: 4,
    image: blog4,
    date: "14 March 2022",
    profile: "By Admin",
    title: "Information Technology maintanance.",
    contain:
      "Streamline your operations and empower your team with Leakey Tech Inc.'s comprehensive IT solutions.  We don't just fix problems, we prevent them.  By combining software development expertise with hardware engineering knowledge, Leakey Tech tailors solutions to optimize your IT infrastructure, enhance data security, and boost overall efficiency.  Let us handle your IT needs so you can focus on what matters most – growing your business.",
  },
  {
    id: 5,
    image: blog5,
    date: "14 March 2022",
    profile: "By Admin",
    title: "Secure active living depend son repair day ladies now.",
    contain:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since to 00s, when an unknown printer.",
  },
];
export default OurBlogData;
