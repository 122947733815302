import React from "react";
import FAQ from "../../../assets/Images/Faq/FAQ.jpg";
import { Row, Col, Accordion } from "react-bootstrap";
function HomeFAQ() {
  return (
    <>
      <div className="container">
        <div className="spacing">
          <Row className="homeFAQRow">
            <Col xl={6} lg={6} md={6}>
              <div className="titleFont">
                <h3> Frequently Asked Questions </h3>
                <h2>You’ve got questions and we’ve got answers</h2>
              </div>
              <div className="homeFAQ">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      How much does it cost to create a website and management
                      software?
                    </Accordion.Header>
                    <Accordion.Body>
                      At Leakey Tech Inc., we understand that every website and
                      its management needs are unique. We can leverage our
                      expertise in both software development and hardware
                      integration to create a customized solution that
                      seamlessly meets your specific goals. Whether you need a
                      simple website or a complex e-commerce platform with
                      robust management software, we can tailor a solution to
                      fit your budget and vision. Contact us today for a free
                      consultation and discover how Leakey Tech Inc. can bring
                      your specialized website project to life.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Can you help my current site look more professional?
                    </Accordion.Header>
                    <Accordion.Body>
                      Elevate your website's professionalism with a clean,
                      consistent layout, high-quality visuals, and a focused
                      color scheme. Ensure your content is clear, concise, and
                      targets your audience. Prioritize a mobile-friendly
                      design, fast loading speed, and easy navigation. Build
                      trust with clear contact information, testimonials, and a
                      privacy policy. Consider SEO optimization and website
                      analytics for ongoing improvement. By focusing on these
                      key elements, you can make a significant difference in how
                      visitors perceive your website.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      I am not sure if my use is covered. what should i do?
                    </Accordion.Header>
                    <Accordion.Body>
                      Unsure if Leakey Tech Inc. is the right fit for your
                      website? No worries! Review their portfolio (if available)
                      to see their design style and expertise. Alternatively,
                      reach out for a free consultation. Explain your website's
                      purpose, goals, and budget. They can assess your project
                      and recommend the best approach, even if it means
                      suggesting another company if Leakey Tech isn't the
                      perfect match. This ensures you find the ideal solution to
                      elevate your website's professionalism and functionality.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Does Leakey Tech Inc. offer ongoing website maintenance
                      and support?
                    </Accordion.Header>
                    <Accordion.Body>
                      Absolutely! Leakey Tech Inc. understands that websites
                      require ongoing maintenance and updates to ensure
                      security, functionality, and optimal performance. They
                      offer various website maintenance packages to suit your
                      needs. These packages can include tasks like: Regular
                      security updates and vulnerability patching Content
                      updates and revisions Performance monitoring and
                      optimization Backup and disaster recovery solutions By
                      partnering with Leakey Tech Inc. for ongoing maintenance,
                      you can ensure your website remains secure, up-to-date,
                      and functioning smoothly, allowing you to focus on your
                      core business activities.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>
            <Col xl={6} lg={6} md={6}>
              <img className="img-fluid mx-auto" alt="AboutUs" src={FAQ} />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default HomeFAQ;
