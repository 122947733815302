import React from "react";
import Slider from "react-slick";
import Testimonial2 from "../../../assets/Images/Testimonial/Testimonial2.png";
import Testimonial3 from "../../../assets/Images/Testimonial/Testimonial3.png";
import Testimonial4 from "../../../assets/Images/Testimonial/Testimonial4.png";

function PeopleSay() {
  const settings = {
    autoplay: true,
    autoplaySpeed: 1500,
    dots: false,
    aroow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="spacing">
        <div className="peopleSayBgColor">
          <div className="container">
            <div className="titleFont text-center">
              <h3>What People Say</h3>
              <h2>What Our Client Said</h2>
            </div>
            <Slider {...settings}>
              <div className="peopleSay text-center">
                <img
                  className="img-fluid mx-auto"
                  alt="PeopleSay"
                  src={Testimonial2}
                />
                <div className="peopleDetails">
                  <p>
                    I recently partnered with Leakey Tech Inc. to revamp my
                    outdated website, and I couldn't be happier with the
                    results. Their unique blend of software development and
                    hardware engineering expertise initially intrigued me, but
                    it was their focus on cutting-edge solutions and robust
                    security that truly won me over.
                  </p>
                  <h3>Truckmart Africa</h3>
                  <h6>Moto-Industry</h6>
                </div>
              </div>
              <div className="peopleSay text-center">
                <img
                  className="img-fluid mx-auto"
                  alt="PeopleSay"
                  src={Testimonial3}
                />
                <div className="peopleDetails">
                  <p>
                    Consider Leakey Tech Inc.'s account management solutions.
                    Their team of experts leverages a unique blend of software
                    and hardware expertise to craft custom tools that streamline
                    workflows, enhance data security, and empower you to build
                    stronger client relationships. From automating tasks to
                    providing real-time insights, Leakey Tech can elevate your
                    account management strategy to the next level.
                  </p>
                  <h3>Rajul Shah</h3>
                  <h6>Senior Accountant</h6>
                </div>
              </div>
              <div className="peopleSay text-center">
                <img
                  className="img-fluid mx-auto"
                  alt="PeopleSay"
                  src={Testimonial4}
                />
                <div className="peopleDetails">
                  <p>
                    I trust Leakey Tech to handle all IT related challenges for
                    our organization as we focus on scaling new highs in our
                    expertise.
                  </p>
                  <h3>Fahima Saleh</h3>
                  <h6>Truckmart General Manager</h6>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}

export default PeopleSay;
