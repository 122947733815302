import { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Button, Collapse } from "react-bootstrap";
import ThemeLogo from "../../assets/Images/BrandLogo/ThemeLogo.png";

export const Footer = () => {
  const [Useopen, UsesetOpen] = useState(false);
  const [Copen, CsetOpen] = useState(false);
  const [Aopen, AsetOpen] = useState(false);

  return (
    <>
      <div className="footer">
        <Container>
          <Row className="Footerbox">
            <Col xl={5} lg={5} md={4} sm={12}>
              <div className="F-col-1 footer-text">
                <img
                  src={ThemeLogo}
                  alt="themeLogo"
                  className="img-fluid mx-auto WLogo"
                />
                <p>Build with us.</p>
                <p>www.leakeyinc.co.ke</p>
              </div>
            </Col>
            <Col xl={2} lg={2} md={2}>
              <div className="F-col-2 footer-text FooterVertical">
                <h3 className=" footerLinkHeading">
                  Useful Links
                  <Button
                    className="Footer-button"
                    onClick={() => UsesetOpen(!Useopen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={Useopen}
                  ></Button>
                </h3>
                <Collapse in={Useopen}>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/aboutus">About Us</Link>
                    </li>
                    <li>
                      <Link to="/planing">Price List</Link>
                    </li>
                    <li>
                      <Link to="/portfolio">Case stories</Link>
                    </li>
                    <li>
                      <Link to="/aboutus">Latest news</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </Collapse>
              </div>
            </Col>
            <Col xl={2} lg={2} md={2}>
              <div className="F-col-3 footer-text FooterVertical">
                <h3 className=" footerLinkHeading">
                  Services
                  <Button
                    className="Footer-button"
                    onClick={() => CsetOpen(!Copen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={Copen}
                  ></Button>
                </h3>
                <Collapse in={Copen}>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/faq">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/service">Help</Link>
                    </li>
                    <li>
                      <Link to="/faq">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/planing">Terms of use</Link>
                    </li>
                    <li>
                      <Link to="/contact">Support</Link>
                    </li>
                  </ul>
                </Collapse>
              </div>
            </Col>

            <Col xl={3} lg={3} md={4} sm={12}>
              <div className="F-col-4 footer-text FooterVertical ">
                <h3 className="footerLinkHeading">
                  Contact Us
                  <Button
                    className="Footer-button"
                    onClick={() => AsetOpen(!Aopen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={Aopen}
                  ></Button>
                </h3>
                <Collapse in={Aopen}>
                  <div className="quickContact">
                    <div className="contactUsIconContain">
                      <i className="fa fa-map-marker"></i>
                      <span>Along Waiyaki Way.</span>
                    </div>
                    <div className="contactUsIconContain">
                      <i className="fas fa-phone-alt"></i>
                      <span>+254 714 11 6032</span>
                    </div>
                    <div className="contactUsIconContain">
                      <i className="fas fa-envelope"></i>
                      <span>infor@leakeytech.co.ke</span>
                    </div>
                  </div>
                </Collapse>
                <ul className="list-unstyled social-icon">
                  <li className="d-inline-block">
                    <a href="/" className="d-block">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a href="https://www.leakeyinc.co.ke" className="d-block">
                      <i className="fa fa-google"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a href="https://x.com/LeakeyBraxton" className="d-block">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a
                      href="https://www.instagram.com/invites/contact/?i=dx24372s4s91&utm_content=hrax4pt"
                      className="d-block"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a
                      href="https://www.linkedin.com/company/leakey-tec-inc"
                      className="d-block"
                    >
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-bottom">
        <h6 className="F-copywrite">Copyright © 2022 . All Rights Reserved</h6>
        <h6 className="F-copywrite">Powered by Leakey Inc</h6>
      </div>
    </>
  );
};
export default Footer;
