import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Icon1 from "../../../assets/Images/Choose/Icon1.png";
import Icon2 from "../../../assets/Images/Choose/Icon2.png";
import Icon3 from "../../../assets/Images/Choose/Icon3.png";
import Icon4 from "../../../assets/Images/Choose/Icon4.png";
import CountUp from "react-countup";
import Aos from "aos";
import "aos/dist/aos.css";

export default function ChooseUs() {
  useEffect(() => {
    Aos.init({ duration: 4000 });
  }, []);
  return (
    <>
      <div className="chooseBanneImg">
        <div className="container">
          <Row className="align-items-center">
            <Col xl={7} lg={7} md={6} sm={12} data-aos="fade-up">
              <div className="titleFont">
                <h3>Why Choose Leakey Tech Inc.?</h3>
                <h2>
                  At Leakey Tech Inc., we don't just follow trends, we set them.
                  We're a forward-thinking company that merges software
                  development and hardware engineering expertise to bring your
                  most innovative ideas to life.{" "}
                </h2>
              </div>
              <p className="paragraph">
                Pioneering Spirit and Full Spectrum of Expertise: Choose Leakey
                Tech Inc. and unlock the potential of your next big idea.
              </p>
              <button className="button">
                <Link to="/service">View Projects</Link>
              </button>
            </Col>
            <Col xl={5} lg={5} md={6} sm={12} data-aos="flip-down">
              <Row>
                <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                  <div className="chooseBox text-center">
                    <img
                      className="img-fluid mx-auto"
                      alt="Choose us"
                      src={Icon1}
                    />
                    <h3>
                      <CountUp end={2570} duration={2.74} />
                    </h3>
                    <h6>Happy Clients</h6>
                  </div>
                </Col>
                <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                  <div className="chooseBox text-center">
                    <img
                      className="img-fluid mx-auto"
                      alt="Choose us"
                      src={Icon2}
                    />
                    <h3>
                      <CountUp end={784} duration={2.75} />
                    </h3>
                    <h6>Project Compelete</h6>
                  </div>
                </Col>
                <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                  <div className="chooseBox text-center">
                    <img
                      className="img-fluid mx-auto"
                      alt="Choose us"
                      src={Icon3}
                    />
                    <h3>
                      <CountUp end={37} duration={2.75} />
                    </h3>
                    <h6>Awards Earned </h6>
                  </div>
                </Col>
                <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                  <div className="chooseBox text-center">
                    <img
                      className="img-fluid mx-auto"
                      alt="Choose us"
                      src={Icon4}
                    />
                    <h3>
                      <CountUp end={10} duration={2.75} />
                    </h3>
                    <h6>Years Experience</h6>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
