import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import AboutMenuBlog from "../../../assets/Images/AboutUs/AboutMenuBlog.png";
import { Row, Col } from "react-bootstrap";
import "../../../theme/css/AboutUs.css";
import AboutUsVideo from "../AboutUs/AboutUsVideo";
import aboutus from "../../../assets/Images/AboutUs/aboutus.jpg";
import aboutusImg1 from "../../../assets/Images/AboutUs/aboutusImg1.jpg";
import aboutusImg3 from "../../../assets/Images/AboutUs/aboutusImg3.jpg";
import aboutusImg4 from "../../../assets/Images/AboutUs/aboutusImg4.jpg";
import OurTeam from "../HomePage/OurTeam";
import Slider from "react-slick";
import Aos from "aos";
import "aos/dist/aos.css";
import Brandlogo from "../HomePage/Brandlogo";
export default function AboutUsMenu() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const settings = {
    dots: false,
    aroow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="otherPageBanner contactUsBanner">
        <div className="text-center title">
          <h2>ABOUT OUR BUSINESS</h2>
          <h6>We are specialize in startups</h6>
        </div>
      </div>
      <div className="container">
        <div className="aboutMenu ">
          <Row>
            <Col xl={6} lg={6} md={6} sm={7}>
              <Slider {...settings}>
                <img
                  className="img-fluid mx-auto"
                  alt="AboutMenu"
                  data-aos="flip-left"
                  src={aboutus}
                />
                <img
                  className="img-fluid mx-auto"
                  alt="AboutMenu"
                  data-aos="flip-left"
                  src={aboutusImg1}
                />
                <img
                  className="img-fluid mx-auto"
                  alt="AboutMenu"
                  data-aos="flip-left"
                  src={aboutusImg3}
                />
                <img
                  className="img-fluid mx-auto"
                  alt="AboutMenu"
                  data-aos="flip-left"
                  src={aboutusImg4}
                />
              </Slider>
            </Col>
            <Col xl={6} lg={6} md={6} sm={5} className="colForResponsive">
              <div className="titleFont">
                <h3>ABOUT OUR BUSINESS</h3>
                <h2>We develop and create successful startup strategy</h2>
              </div>
              <p>
                Building the Cornerstones of Innovation: Your Startup Success
                Partner At Leakey Tech Inc, we're passionate about igniting the
                spark of innovation and turning it into a thriving startup. We
                don't just develop strategies – we craft launchpads for success.
                <h3>Our Expertise, Your Advantage:</h3>
                Our team brings a unique blend of experience and knowledge to
                the table: Startup Savvy: We understand the unique challenges
                and opportunities faced by startups. We've been there, guiding
                countless entrepreneurs from ideation to market validation.
                Strategic Insight: We don't offer a one-size-fits-all approach.
                We delve deep to understand your vision, target market, and
                competitive landscape, crafting a bespoke strategy tailored to
                your specific needs. Actionable Plans: Our strategies aren't
                just theoretical roadmaps. We translate vision into actionable
                plans with clear milestones, ensuring you have a roadmap to
                success.
                <h3>Beyond the Launchpad:</h3>
                Our partnership doesn't end at launch. We offer ongoing support
                to ensure your startup continues to thrive. From growth hacking
                to navigating funding rounds, we'll be by your side every step
                of the way. Partner with Leakey Tech Inc and turn your
                innovative idea into a reality. Let's build your startup success
                story together.
              </p>
              <button className="button">
                <Link to="/service">Explore Solutions </Link>
              </button>
            </Col>
          </Row>
        </div>
        <div className="aboutusMenuCounting">
          <Row>
            <Col xl={3} lg={3} md={3} sm={6} xs={6} data-aos="zoom-in-up">
              <h2>8M+</h2>
              <h6>Trusted user</h6>
            </Col>
            <Col xl={3} lg={3} md={3} sm={6} xs={6} data-aos="zoom-in-up">
              <h2>64K</h2>
              <h6>Telephonic talk</h6>
            </Col>
            <Col xl={3} lg={3} md={3} sm={6} xs={6} data-aos="zoom-in-up">
              <h2>9M+</h2>
              <h6>Project completed</h6>
            </Col>
            <Col xl={3} lg={3} md={3} sm={6} xs={6} data-aos="zoom-in-up">
              <h2>250</h2>
              <h6>Award winning</h6>
            </Col>
          </Row>
        </div>

        <AboutUsVideo />
      </div>
      <OurTeam />
      <div className="spacing">
        <Brandlogo />
      </div>
    </>
  );
}
