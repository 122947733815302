import React from "react";
import Banner from "../HomePage/Banner"
import AboutUs from '../HomePage/AboutUs'
import OurPricing from "./OurPricing";
import OurTeam from "./OurTeam";
import OurBlog from "./OurBlog";
import Brandlogo from "./Brandlogo";
import StartedToday from "./StartedToday";
import WeProvide from "./WeProvide";
import ChooseUs from "./ChooseUs";
import HomeFAQ from "./HomeFAQ";
import PeopleSay from "./PeopleSay";


export default function Home() {

  return (
    <>
   
      <Banner/>
      <AboutUs/>
      <WeProvide/>
      <ChooseUs/>
      <HomeFAQ/>
      <OurPricing/>
      <OurTeam/>
      <PeopleSay/>
      <OurBlog/>
      <Brandlogo/>
      <StartedToday/>  
    </>
  );
}
