import React, { Component } from "react";
import Slider from "react-slick";
import { Row, Col } from "react-bootstrap";
import quote from "../../../assets/Images/Testimonial/quote.png";
import Testimonial1 from "../../../assets/Images/Testimonial/Testimonial1.png";
import Testimonial2 from "../../../assets/Images/Testimonial/Testimonial2.png";
import Testimonial3 from "../../../assets/Images/Testimonial/Testimonial3.png";
import Testimonial4 from "../../../assets/Images/Testimonial/Testimonial4.png";

import "aos/dist/aos.css";
export default class OurTestimonial extends Component {
  render() {
    const settings = {
      dots: false,
      aroow: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <>
        <div className="container">
          <div className="ourTestimonialMain">
            <div className="titleFont">
              <h3>Our Testimonial</h3>
              <h2>What Our Client Said</h2>
            </div>
            <div className="ourTestimonialCard text-center">
              <Row>
                <Slider {...settings}>
                  <Col xl={4} lg={4} md={4} className="ourTestimonialCol">
                    <div
                      className="testimonialCard featuresCard"
                      data-aos="fade-up"
                    >
                      <img
                        className="img-fluid mx-auto testimonialImg"
                        src={Testimonial1}
                        alt="ourTestimonial"
                      />
                      <img
                        className="img-fluid mx-auto testimonialQuote"
                        src={quote}
                        alt="ourTestimonial"
                      />
                      <p className="paragraph">
                        All management softwares build by Leakey Tech Inc are up
                        to scale and provide the necessesary support in
                        maintaining an institutions accounts department. The
                        attention to details provides all the needed adjustment
                        to the pre-existing technologies and then some.
                      </p>
                      <h6>KLEIN MATANCHI</h6>
                      <h2>ACCOUNTS EXPORT</h2>
                    </div>
                  </Col>
                  <Col xl={4} lg={4} md={4} className="ourTestimonialCol">
                    <div
                      className="testimonialCard featuresCard"
                      data-aos="fade-up"
                    >
                      <img
                        className="img-fluid mx-auto testimonialImg"
                        src={Testimonial2}
                        alt="ourTestimonial"
                      />
                      <img
                        className="img-fluid mx-auto testimonialQuote"
                        src={quote}
                        alt="ourTestimonial"
                      />
                      <p className="paragraph">
                        Leakey Tech Inc’s approach to teaching technical
                        concepts is particularly impressive. They break down
                        complex ideas into manageable sections, making it easier
                        for students to grasp difficult concepts.
                      </p>
                      <h6>Howam Productions</h6>
                      <h2>Online School</h2>
                    </div>
                  </Col>
                  <Col xl={4} lg={4} md={4} className="ourTestimonialCol">
                    <div
                      className="testimonialCard featuresCard"
                      data-aos="fade-up"
                    >
                      <img
                        className="img-fluid mx-auto testimonialImg"
                        src={Testimonial3}
                        alt="ourTestimonial"
                      />
                      <img
                        className="img-fluid mx-auto testimonialQuote"
                        src={quote}
                        alt="ourTestimonial"
                      />
                      <p className="paragraph">
                        I highly recommend Leakey Tech Inc as featured in the
                        online courses provided by Howam Productions. Their
                        curriculum is both comprehensive and practical, designed
                        to cater to learners at all levels.
                      </p>
                      <h6>DIANA QOI</h6>
                      <h2>CEO</h2>
                    </div>
                  </Col>
                  <Col xl={4} lg={4} md={4} className="ourTestimonialCol">
                    <div
                      className="testimonialCard featuresCard"
                      data-aos="fade-up"
                    >
                      <img
                        className="img-fluid mx-auto testimonialImg"
                        src={Testimonial4}
                        alt="ourTestimonial"
                      />
                      <img
                        className="img-fluid mx-auto testimonialQuote"
                        src={quote}
                        alt="ourTestimonial"
                      />
                      <p className="paragraph">
                        The support provided throughout the course is top-notch,
                        with knowledgeable instructors and a community that
                        fosters collaborative learning.
                      </p>
                      <h6>Howam Production Students</h6>
                      {/* <h2>Web Designer</h2> */}
                    </div>
                  </Col>
                </Slider>
              </Row>
            </div>
          </div>
        </div>
      </>
    );
  }
}
