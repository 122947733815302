import React from "react";
import { Row, Col, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FormControl } from "react-bootstrap";
import icon from "../../../assets/Images/BrandLogo/icon.png";
export default function StartedToday() {
  return (
    <>
      <div className="startedToday">
        <div className="container">
          <div className="startedTodayMainDivFloat">
            <Row>
              <Col xl={3} lg={1} md={1} sm={1}>
                <div className="startedTodayMainDiv">
                  <img
                    className="img-fluid mx-auto "
                    alt="AboutUs"
                    src={icon}
                  />
                  <div className="">
                    <span>SIGN UP FOR NEWSLETTER</span>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={4} md={4} sm={4} className="startedTodaySideTextCol">
                <div className="startedTodaySideText">
                  <p>
                    Subscribe To The Weekly Newsletter For All The Latest
                    Updates
                  </p>
                </div>
              </Col>
              <Col xl={5} lg={7} md={7} sm={7} >
                <div className="startedEmailBox">
                  <InputGroup className="">
                    <FormControl placeholder="Your Email..." />
                    <button className="button">
                      <Link to="/service"> Subscribe Now </Link>
                    </button>
                  </InputGroup>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
