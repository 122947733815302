import React from "react";
import "../../../../src/theme/css/HomePage.css";
import Banner from "../../../assets/Images/Banner/Banner.jpg";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <>
      <div className="mainBannerDiv">
        <img className="img-fluid mx-auto" src={Banner} alt="BannerImage" />
        <div className="container">
          <div className="bannerContain text-center" data-aos="zoom-in">
            <h1>LEAKEY TECH INC</h1>
            <h3>
              TECHNOLOGICAL RESEARCH, SOFTWARE DESIGN AND BUILDING, WEBSITE
              DESIGNS, CLOUD HOSTING
            </h3>
            <h5>
              Leakey Inc provides services on all things technology. Reach out
              for consultations and guidance.{" "}
            </h5>
            <button className="button">
              <Link to="/service">Get Started </Link>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
