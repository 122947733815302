import React, { useEffect } from "react";
import aboutus from "../../../assets/Images/AboutUs/aboutus.jpg";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
export default function AboutUs() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="container">
        <div className="aboutus spacing">
          <Row className="aboutRow">
            <Col xl={6} lg={6} md={6} sm={5} xs={12}>
              <img
                className="img-fluid mx-auto"
                alt="AboutUs"
                src={aboutus}
                data-aos="flip-down"
              />
            </Col>

            <Col xl={6} lg={6} md={6} sm={7} xs={12} data-aos="fade-up">
              <div className="titleFont">
                <h3>About LEAKEY INC</h3>
                <h2>With the lastest technology in the market:</h2>
              </div>
              <p className="paragraph">
                Leakey Tech Inc. is a pioneering tech company poised to make a
                significant impact across software, hardware, cybersecurity, and
                robotics. With a unique blend of expertise in both software
                development and hardware engineering, Leakey Tech is
                well-positioned to tackle complex challenges and deliver
                cutting-edge solutions.
              </p>
              <div className="aboutUsPoint">
                <Row>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                    <div className="aboutCheckPoint">
                      <i className="fas fa-check-circle"></i>
                      <span>Brilient Client Service</span>
                    </div>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                    <div className="aboutCheckPoint">
                      <i className="fas fa-check-circle"></i>
                      <span>24/7 Support</span>
                    </div>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                    <div className="aboutCheckPoint">
                      <i className="fas fa-check-circle"></i>
                      <span>Friendly Support</span>
                    </div>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                    <div className="aboutCheckPoint">
                      <i className="fas fa-check-circle"></i>
                      <span>Unique Design</span>
                    </div>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                    <div className="aboutCheckPoint">
                      <i className="fas fa-check-circle"></i>
                      <span>Proffesional</span>
                    </div>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                    <div className="aboutCheckPoint">
                      <i className="fas fa-check-circle"></i>
                      <span>Best Services</span>
                    </div>
                  </Col>
                </Row>
              </div>
              <button className="button">
                <Link to="/service">More About Us </Link>
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
